<template>
  <v-dialog
    v-model="isShowDialog"
    :max-width="maxWidth"
    class="mx-auto"
    :persistent="persistent"
    :overlay-color="overlayColor"
    :overlay-opacity="overlayOpacity"
  >
    <v-card>
      <v-form ref="dialogForm">
        <v-card-title class="px-6 pb-5">
          {{ title }}
          <v-spacer></v-spacer>
          <slot name="title-right"></slot>
        </v-card-title>
        <v-card-text
          class="px-6 pb-5 app-card-text"
          :class="textClass"
          :style="cardTextStyle"
        >
          <slot></slot>
        </v-card-text>
        <v-divider v-if="actionDivider"></v-divider>
        <v-card-actions>
          <slot name="action-ex"></slot>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!!actionText"
            text
            :color="color"
            :disabled="loading"
            :loading="loading"
            @click="actionBtnClicked"
          >
            <v-icon v-if="!!actionIcon" class="mr-2">{{ actionIcon }}</v-icon>
            {{ actionText }}
          </v-btn>
          <v-btn v-if="showCancel" text @click="isShowDialog = false">
            {{ cancelText }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    size: {
      type: String
    },
    persistent: {
      type: Boolean,
      default: false
    },
    overlayColor: {
      type: String,
      default: undefined
    },
    overlayOpacity: {
      type: String,
      default: undefined
    },
    actionDivider: {
      type: Boolean,
      default: false
    },
    color: {
      type: String
    },
    title: {
      type: String
    },
    overflowHeight: {
      type: String
    },
    textStyle: {
      type: String
    },
    textClass: {
      type: String
    },
    actionText: {
      type: String
    },
    actionIcon: {
      type: String
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    cancelText: {
      type: String,
      default: "取消"
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isShowDialog: this.value
    };
  },

  model: {
    prop: "value",
    event: "change"
  },

  watch: {
    value(newVal) {
      this.isShowDialog = newVal;
    },
    isShowDialog(newVal) {
      this.$emit("change", newVal);
      newVal || this.dialogClosed();
    }
  },

  computed: {
    maxWidth() {
      let dialogMaxWidth = "600px";
      if (this.size === "small") dialogMaxWidth = "400px";
      if (this.size === "medium") dialogMaxWidth = "600px";
      if (this.size === "large") dialogMaxWidth = "800px";
      if (this.size === "x-large") dialogMaxWidth = "1000px";
      return dialogMaxWidth;
    },
    cardTextStyle() {
      let style = this.textStyle || "";
      if (this.overflowHeight) {
        style += `height:calc(100vh - ${this.overflowHeight});overflow:auto;`;
      }
      return style;
    }
  },

  methods: {
    actionBtnClicked() {
      let isValid = this.$refs.dialogForm.validate();
      if (isValid) {
        this.$emit("confirm");
      }
    },
    dialogClosed() {
      this.$emit("closing");
      this.isShowDialog = false;
      this.$nextTick(() => {
        this.$refs.dialogForm.resetValidation();
        this.$emit("closed");
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.app-card-text {
  color: rgba(0, 0, 0, 0.6);
}
</style>
